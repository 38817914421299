<template>
  <div class="space-y-10">
    <div class="lg:grid lg:grid-cols-3 gap-12 mt-4">
      <!-- Castlemartyr Resort -->
      <div class="space-y-8">
        <h1 class="font-semibold text-3xl">Castlemartyr Resort</h1>
        <p>Castlemartyr, Co. Cork, P25 X300, Ireland</p>
        <p>
          Adjacent to the ruins of a 13th-century castle, this upscale country
          hotel on 220 acres of parkland is 10 km from the Jameson Distillery.
        </p>
        <p>
          Sophisticated rooms in the 18th-century manor house and an adjoining
          annexe feature free Wi-Fi, flat-screen TVs and marble bathrooms with
          rainfall showers, plus minifridges and Nespresso coffeemakers. Suites
          add separate sitting rooms, and some have balconies.
        </p>
        <p>
          A plush spa includes an indoor pool, a fitness centre and a cafe, as
          well as a sauna and a steam room. There's a golf course, and dining
          options include an elegant restaurant with modern Irish cuisine and a
          cosy bar with a fireplace.
        </p>
        <hr />
        <!-- icons -->
        <div class="grid grid-cols-2 gap-4">
          <div class="flex items-center space-x-3">
            <i class="mdi mdi-wifi text-lg"></i>
            <span>Free Wi-Fi</span>
          </div>
          <div class="flex items-center space-x-3">
            <i class="mdi mdi-parking text-lg"></i>
            <span>Free Parking</span>
          </div>
          <div class="flex items-center space-x-3">
            <i class="mdi mdi-pool text-lg"></i>
            <span>Pool</span>
          </div>
          <div class="flex items-center space-x-3">
            <i class="mdi mdi-washing-machine text-lg"></i>
            <span>Laundry Service</span>
          </div>
        </div>
        <hr />
        <!-- buttons  -->
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 lg:grid-cols-1">
          <div>
            <router-link
              :to="'/' + $store.state.selectedPartner.id + '/p/' + 'ir2bd'"
              class="
                uppercase
                bg-pink-600
                text-pink-50
                hover:bg-pink-700
                font-semibold
                cursor-pointer
                text-sm
                tracking-wide
                transition
                rounded-full
                px-4
                py-3
                block
                text-center
              "
            >
              View Our 7 Night Castlemartyr Package
            </router-link>
          </div>
          <div>
            <router-link
              :to="'/' + $store.state.selectedPartner.id + '/p/' + '2d200'"
              class="
                uppercase
                bg-purple-600
                text-purple-50
                hover:bg-purple-700
                font-semibold
                cursor-pointer
                text-sm
                tracking-wide
                transition
                rounded-full
                px-4
                py-3
                block
                text-center
              "
            >
              View Our 7 Night Maui / Ireland Package
            </router-link>
          </div>
          <div>
            <router-link
              :to="'/' + $store.state.selectedPartner.id + '/europe-ireland'"
              class="
                uppercase
                bg-blue-600
                text-blue-50
                hover:bg-blue-700
                font-semibold
                cursor-pointer
                text-sm
                tracking-wide
                transition
                rounded-full
                px-4
                py-3
                block
                text-center
              "
            >
              Browse Europe &amp; Ireland Packages
            </router-link>
          </div>
          <div>
            <button
              @click="downloadPdf"
              :to="'/' + $store.state.selectedPartner.id + '/p/' + '2d200'"
              class="
                uppercase
                bg-gray-600
                text-gray-50
                hover:bg-gray-700
                font-semibold
                cursor-pointer
                text-sm
                tracking-wide
                transition
                rounded-full
                px-4
                py-3
                block
                text-center
                w-full
              "
            >
              Download Castlemartyr PDF
            </button>
          </div>
        </div>
      </div>
      <!-- pdf embed -->
      <div
        class="
          space-y-8
          col-span-2
          shadow
          md:overflow-y-auto md:h-3/4
          mt-10
          lg:mt-2
        "
      >
        <vue-pdf-embed :source="pdfSource" />
      </div>

      <!-- best sellers -->
      <div class="col-span-3 space-y-10">
        <div>
          <hr />
        </div>
        <div class="pageTitle">
          <h1>Our Best Selling Packages</h1>
          <h3>A great starting point for any event</h3>
        </div>
        <RetailPackages :items="$store.state.retailPackages" />
      </div>
    </div>
  </div>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed";
// https://www.npmjs.com/package/vue-pdf-embed
import RetailPackages from "@/components/RetailPackages";
export default {
  components: { RetailPackages, VuePdfEmbed },
  data: () => ({
    pdfSource:
      "https://firebasestorage.googleapis.com/v0/b/silent-auction-vacations.appspot.com/o/pdfs%2Fcastlemartyr.pdf?alt=media&token=4adb9abc-f6e0-44d7-bac1-335939d6c6e0",
  }),
  methods: {
    downloadPdf() {
      if (!this.pdfSource) return;
      let win = window.open(this.pdfSource, "_blank");
      win.focus();
    },
  },
};
</script>
